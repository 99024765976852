@import '@crystal-eyes/constants.scss';

.container {
  border-radius: 4px;
  position: absolute;
  bottom: 30px;
  background-color: #4d6977;
  color: $white;
  padding: 16px;

  svg {
    stroke: $light-gray-text-two;
    width: 15px;
    height: 15px;
    stroke-width: 1;
  }

  .navLinkList {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .navLink {
      font-size: 0.875rem;
      display: flex;
      align-items: center;
      color: $white;
      gap: 8px;
    }
  }

  .privacyAndTermsLink {
    svg {
      stroke: $light-gray-text-two;
      width: 15px;
      height: 15px;
      stroke-width: 1;
    }

    a {
      color: $white;
    }
  }
}
